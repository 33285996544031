import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'

const showcases = [
  { imageUrl: '/assets/images/show-case-3.jpg', titleUrl: '/assets/images/showcase-title-1.png', description: 'Discover Microsoft\'s blockchain-based digital badge rewards program.' },
  { imageUrl: '/assets/images/show-case-2.jpg', titleUrl: '/assets/images/showcase-title-2.png', description: 'Discover Microsoft\'s blockchain-based digital badge rewards program.' },
  { imageUrl: '/assets/images/show-case-1.jpg', titleUrl: '/assets/images/showcase-title-1.png', description: 'Discover Microsoft\'s blockchain-based digital badge rewards program.' },
]

export function Showcase() {
  return (
    <section className="max-w-8xl mx-auto py-8 xl:py-12">
      <div className="text-white text-xl font-bold text-center xl:text-5xl wow fadeInDown">Showcase</div>
      <div className="py-8 flex snap-x snap-mandatory overflow-x-auto xl:overflow-hidden xl:grid xl:grid-cols-3 xl:gap-8 xl:py-12 wow fadeInUp">
        {showcases.map((showcase, i) => (
          <div key={i} className="xl:col-span-1 snap-center px-2">
            <div className="w-56 relative xl:w-full">
              <img className="transition duration-500 ease-in-out rounded-4xl transform hover:scale-105" src={showcase.imageUrl} />
              <div className="absolute bottom-4 left-0 right-0 flex justify-center">
                <img className="w-1/2" src={showcase.titleUrl} />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-white py-6 text-center">{showcase.description}</div>
              <a href="#" className="text-yellow">Read more</a>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden text-center xl:block wow fadeInUp">
        <button className="btn btn-primary uppercase font-bold">
          Go to shop
        </button>
      </div>
    </section>
  )
}
