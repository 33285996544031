import React from "react";

export function GetStarted() {
  return (
    <section className="max-w-8xl px-5 mx-auto py-8 xl:py-12 flex flex-col-reverse xl:flex-row items-center">
      <img className="w-full pr-0 xl:pr-10 transition duration-500 ease-in-out transform hover:scale-110 wow fadeInLeft" alt="get-started" src="/assets/images/get-started.png" />
      <div className="w-full pl-0 xl:pl-10 mb-6 xl:mb-0 wow fadeInRight">
        <div className="text-white font-bold text-2xl xl:text-5xl pb-6 xl:pb-14">Get started with NFTs</div>
        <div className="text-xs xl:text-lg text-yellow pb-2 xl:pb-3">Platform</div>
        <div className="text-white leading-6 xl:leading-8 xl:text-2xl font-bold pb-2 xl:pb-3">Create, distribute, and integrate NFTs</div>
        <div className="text-gray text-sm xl:text-base leading-6 xl:leading-8 pb-8 xl:pb-20">
          Bring your NFTs to life via a simple interface, send them through QR codes, and give them utility using tools that make it feel effortless.
        </div>
        <button className="btn btn-primary font-bold"><span className="text-sm xl:text-base px-8 py-2 xl:p-0">GO TO SHOP</span></button>
      </div>
    </section>
  );
}
