import React from 'react';
import { chunk } from 'lodash-es';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useBreakPoints } from '@utils/breakpoints';
import { SayingAboutUsCard } from '@components/Home/SayingAboutUsCard';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

const sayings = [
  { avatarUrl: '/assets/images/saying-1.png', name: 'Sam Stebbins', title: 'Jade Stems Studios', description: '“Enjin has provided us more than just the technology we needed to integrate NFTs without any blockchain code—their loyal following has helped us foster a community of incredible supporters for our game.”' },
  { avatarUrl: '/assets/images/saying-2.png', name: 'Sam Stebbins', title: 'Jade Stems Studios', description: '“Enjin has provided us more than just the technology we needed to integrate NFTs without any blockchain code—their loyal following has helped us foster a community of incredible supporters for our game.”' },
  { avatarUrl: '/assets/images/saying-3.png', name: 'Sam Stebbins', title: 'Jade Stems Studios', description: '“Enjin has provided us more than just the technology we needed to integrate NFTs without any blockchain code—their loyal following has helped us foster a community of incredible supporters for our game.”' },
  { avatarUrl: '/assets/images/saying-1.png', name: 'Sam Stebbins', title: 'Jade Stems Studios', description: '“Enjin has provided us more than just the technology we needed to integrate NFTs without any blockchain code—their loyal following has helped us foster a community of incredible supporters for our game.”' },
  { avatarUrl: '/assets/images/saying-2.png', name: 'Sam Stebbins', title: 'Jade Stems Studios', description: '“Enjin has provided us more than just the technology we needed to integrate NFTs without any blockchain code—their loyal following has helped us foster a community of incredible supporters for our game.”' },
  { avatarUrl: '/assets/images/saying-3.png', name: 'Sam Stebbins', title: 'Jade Stems Studios', description: '“Enjin has provided us more than just the technology we needed to integrate NFTs without any blockchain code—their loyal following has helped us foster a community of incredible supporters for our game.”' },
]

export function SayingAboutUs() {
  const { breakpoint } = useBreakPoints();

  return (
    <section className="max-w-8xl px-5 mx-auto py-8 xl:py-12">
      <div className="text-base text-yellow wow fadeInLeft">Used by Businesses Like Yours</div>
      <div className="text-white text-2xl font-bold leading-normal mb-12 xl:text-5xl wow fadeInLeft">
        Here's what people <br />
        are saying about us
      </div>
      {breakpoint === 'desktop' && (
        <PerfectScrollbar>
          <div className="flex flex-nowrap w-max px-4 wow fadeInUp">
            {sayings.map((item, i) => (
              <SayingAboutUsCard key={i} item={item} />
            ))}
          </div>
        </PerfectScrollbar>
      )}
      {breakpoint === 'mobile' && (
        <Swiper
          className="mb-8 wow fadeInUp"
          modules={[Pagination]}
          pagination={{ clickable: true }}
        >
          {chunk(sayings, 3).map((col, i) => (
            <SwiperSlide className="pb-6" key={i}>
              {col.map((item, j) => <SayingAboutUsCard item={item} key={j} />)}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className="text-white text-2xl text-center font-bold mt-32 mb-8 xl:mb-11 xl:text-5xl wow fadeInDown">
        Trusted by millions
      </div>
      <div className="grid grid-cols-12 gap-6 wow fadeInUp">
        <div className="col-start-1 col-span-12 py-6 rounded-4xl trust-container flex items-start justify-around xl:py-12 xl:col-span-10 xl:col-start-2 xl:items-center">
          <div className="text-center">
            <div className="text-yellow font-bold text-4xl xl:text-5xl">1.7M</div>
            <div className="text-xs text-white font-bold xl:text-lg">Wallet Installs</div>
          </div>
          <div className="text-center">
            <div className="text-yellow font-bold text-4xl xl:text-5xl">1.16B</div>
            <div className="text-xs text-white font-bold xl:text-lg">Created Assets</div>
          </div>
          <div className="text-center">
            <div className="text-yellow font-bold text-4xl xl:text-5xl">10.3M</div>
            <div className="text-xs text-white font-bold xl:text-lg">Marketplace <br className='inline xl:hidden' /> Volume (ENJ)</div>
          </div>
        </div>
      </div>
    </section>
  )
}
