import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Header } from "@partials/Header";
import { Footer } from "@partials/Footer";
import { Home } from "@pages/Home";

export function App() {
  useEffect(() => {
    const WOW = require('wowjs/dist/wow');
    new WOW.WOW().init();
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden relative">
      <Header />

      <main className="bg-light-black flex-grow">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </main>

      <Footer />
    </div>
  );
}
