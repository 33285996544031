import React from 'react';

export function TopHero() {
  return (
    <section className='top-hero' style={{ backgroundImage: 'url(/assets/images/bg-top.png)', backgroundSize: 'cover' }}>
      <div className='pt-24 md:pt-40 pb-20 md:pb-52'>
        <div className='max-w-2xl mx-auto flex flex-col items-center'>
          <div className='mb-2'>
            <h1 className='text-white font-semibold text-center leading-normal text-4xl lg:text-5xl wow fadeInUp'>
              NFTs you can use to grow <br className='hidden xl:inline' /><span className='text-red'>your business</span>
            </h1>
          </div>
          <div className='square mx-10 md:mx-0 px-12 md:px-16 pt-12 md:pt-16 mb-8 wow fadeInUp'>
            <img className='rounded-4 mb-6' src='/assets/images/qr-code.jpeg' alt='QR Code' />
            <p className='text-base md:text-lg text-white text-center'>Get your first NFT!<br />
              Scan with <span className='text-yellow'>GAMIFY Wallet</span></p>
          </div>
          <div className='flex gap-x-2 md:gap-x-7 justify-center'>
            <a href="#"><img className='w-40 md:w-auto wow fadeInLeft' src='/assets/images/appstore.png' alt='App Store' /></a>
            <a href="#"><img className='w-40 md:w-auto wow fadeInRight' src='/assets/images/googleplay.png' alt='Google Play' /></a>
          </div>
        </div>
      </div>
    </section>
  )
}
